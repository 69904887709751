import axios, { AxiosError, AxiosResponseHeaders } from 'axios';
import { isProduction } from 'helpers/environment';
import { getDefaultStore } from 'jotai/vanilla';
import { requestsAtom } from 'state/application';
import {
  BidingZoneBordersApi,
  BidingZoneBorderVariablesApi,
  BidingZoneMappingApi,
  BidingZoneVariablesApi,
  DayAheadNetPositionApi,
  DayAheadPriceApi,
  DayAheadPriceSpreadApi,
  DayAheadScheduledCommercialExchangesApi,
  Intraday1NetPositionApi,
  Intraday1PricesApi,
  Intraday1PriceSpreadApi,
  Intraday1ScheduledCommercialExchangesApi,
  Intraday2NetPositionApi,
  Intraday2PricesApi,
  Intraday2PriceSpreadApi,
  Intraday2ScheduledCommercialExchangesApi,
  Intraday3NetPositionApi,
  Intraday3PricesApi,
  Intraday3PriceSpreadApi,
  Intraday3ScheduledCommercialExchangesApi,
  UrgentMarketMessagesApi,
} from './swagger/backend/api';

import {
 ChatBotQuestionsApi
} from './swagger/chatbot/api';

export const urlAPI = isProduction() ? import.meta.env.VITE_BACKEND_API_PROD : import.meta.env.VITE_BACKEND_API_DEV;
export const urlChatbot = import.meta.env.VITE_BACKEND_CHATBOT

export let bidingZoneBordersClient =  new BidingZoneBordersApi(undefined, urlAPI);
export let bidingZoneMappingClient =  new BidingZoneMappingApi(undefined, urlAPI);

export let dayAheadPriceClient =  new DayAheadPriceApi(undefined, urlAPI);
export let intraday1PricesClient =  new Intraday1PricesApi(undefined, urlAPI);
export let intraday2PricesClient =  new Intraday2PricesApi(undefined, urlAPI);
export let intraday3PricesClient =  new Intraday3PricesApi(undefined, urlAPI);

export let dayAheadNetPositionClient =  new DayAheadNetPositionApi(undefined, urlAPI);
export let intraday1NetPositionClient =  new Intraday1NetPositionApi(undefined, urlAPI);
export let intraday2NetPositionClient =  new Intraday2NetPositionApi(undefined, urlAPI);
export let intraday3NetPositionClient =  new Intraday3NetPositionApi(undefined, urlAPI);

export let bidingZoneVariablesClient =  new BidingZoneVariablesApi(undefined, urlAPI);

export let dayAheadScheduledCommercialExchangesClient =  new DayAheadScheduledCommercialExchangesApi(undefined, urlAPI);
export let intraday1ScheduledCommercialExchangesClient =  new Intraday1ScheduledCommercialExchangesApi(undefined, urlAPI);
export let intraday2ScheduledCommercialExchangesClient =  new Intraday2ScheduledCommercialExchangesApi(undefined, urlAPI);
export let intraday3ScheduledCommercialExchangesClient =  new Intraday3ScheduledCommercialExchangesApi(undefined, urlAPI);

export let bidingZoneBorderVariablesClient = new BidingZoneBorderVariablesApi(undefined, urlAPI)

export let dayAheadPriceSpreadClient = new DayAheadPriceSpreadApi(undefined, urlAPI)
export let intraday1PriceSpreadClient = new Intraday1PriceSpreadApi(undefined, urlAPI)
export let intraday2PriceSpreadClient = new Intraday2PriceSpreadApi(undefined, urlAPI)
export let intraday3PriceSpreadClient = new Intraday3PriceSpreadApi(undefined, urlAPI)

export let chatBotQuestionsClient = new ChatBotQuestionsApi(undefined, urlChatbot)

export let urgentMarketMessagesClient = new UrgentMarketMessagesApi(undefined, urlAPI)

const store = getDefaultStore(); // Access the default Jotai store

const getInstance = (accessToken: string, showToast: any, urlAPI: string) => {
  const instance = axios.create({
    baseURL: urlAPI,
    timeout: 45000,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    validateStatus: (status) => status >= 200 && status < 300,
    transformResponse: (
      data: any,
      headers?: AxiosResponseHeaders,
      status?: number,
    ) => {
      try {
        if (status == 401) {
          showToast && showToast("session expired", 'error');
          return;
        }

        if (status == 204) {
          return;
        }

        const responseObject = JSON.parse(data ? data : '{}');

        const errors = status == 400 && responseObject.errors;

        if (status == 400 && !responseObject.errors) {
          showToast && showToast(responseObject.detail || "server wrong Data", 'error');
          return;
        }

        if (errors) {
          const keys = Object.keys(errors);
          keys.forEach((key) => {
            const err = errors[key];
            err.forEach((element: string) => {
              showToast && showToast(element, 'error');
            });
          });
        }

        return responseObject;
      } catch (error: any) {
        const message = error.response?.data?.message || error.message;
        if (message) {
          if (message.includes('not valid JSON')) {
            showToast && showToast('parse error', 'error');
            throw Error("serverError");
          } else {
            showToast && showToast(message, 'error');
          }
        }
        throw error;
      }
    },
  });

   // Request interceptor
   instance.interceptors.request.use(
    (config) => {
      (config as any).metadata = { startTime: performance.now() };
      const requestUrl = config?.url;

      if(requestUrl) {
        store.set(requestsAtom, (prev) => [{
          url: requestUrl,
          startDate: new Date(),
        }, ...prev]);
      }
     
      return config;
    },
    (error) => {
      const requestUrl = error.config?.url;

      const endTime = performance.now();
      const startTime = (error.config as any).metadata.startTime;
      const duration = endTime - startTime; // Duration in milliseconds
      const durationSecs = Math.round(duration/100) / 10

      console.log('error req', error, durationSecs, error.config)

      const reqs = store.get(requestsAtom)
      const f = reqs.find((r) => r.url == requestUrl)

      if(f) {
        store.set(requestsAtom, (prev) => { 
          const ff = prev.find((r) => r.url == requestUrl)
            if(ff) {
              ff.error = error.message,
              ff.duration = durationSecs,
              ff.statusCode = error.response?.status
            }
          return prev
        });
      }

      return Promise.reject(error);
    }
  );

  // Response interceptor
  instance.interceptors.response.use(
    (response) => {
      const requestUrl = response.config.url;

      const endTime = performance.now();
      const startTime = (response.config as any).metadata.startTime;
      const duration = endTime - startTime; // Duration in milliseconds
      const durationSecs = duration < 100 ? Math.round(duration / 10) / 100 : Math.round(duration/100) / 10
  
      const reqs = store.get(requestsAtom)
      const f = reqs.find((r) => r.url == requestUrl)
      
      if(f) {
        window.latestRequestError = ""

        const dataString = JSON.stringify(response.data);
        const dataSizeInBytes = new Blob([dataString]).size;
        const dataSizeInMB = dataSizeInBytes / 1024;
        const formattedSizeInKb = dataSizeInMB < 0.1 ? Math.round(dataSizeInMB * 1000) / 1000 : Math.round(dataSizeInMB * 10) / 10;

        const parsed = response.request.responseText;

        store.set(requestsAtom, (prev) => { 
          const prevNew = [...prev]
          const ff = prevNew.find((r) => r.url == requestUrl)
            if(ff) {
              ff.duration = durationSecs
              ff.size = formattedSizeInKb
              ff.statusCode = response.status
              ff.response = parsed
            }
          return prevNew
        });
      }

      return response;
    },
    (error: AxiosError) => {
      const requestUrl = error.config?.url;

      const endTime = performance.now();
      const startTime = (error.config as any).metadata.startTime;
      const duration = endTime - startTime; // Duration in milliseconds
      const durationSecs = Math.round(duration/100) / 10

      const reqs = store.get(requestsAtom)
      const f = reqs.find((r) => r.url == requestUrl)

      console.log('error res', error, durationSecs, error.config)

      let message = error.message;

      if(f) {
        if (error.response && error.response.status === 400) {
          const errorDetails = error.toJSON();
          console.log('Error details from toJSON():', errorDetails);

          message =  JSON.stringify(error.response?.data)
        }

        if (error.response?.request && typeof error.response.request.responseText === 'string') {
          try {
            const parsed = JSON.parse(error.response.request.responseText);
            message = (parsed.detail || message); // Adjust if your server's response structure differs
          } catch (parseError) {
            console.error('Failed to parse responseText:', parseError);
          }
        }

        if(window.latestRequestError != message) {
          showToast && showToast(message, 'error');
        }
      
        window.latestRequestError = message;

        store.set(requestsAtom, (prev) => { 
          const ff = prev.find((r) => r.url == requestUrl)
            if(ff) {
              ff.error = message,
              ff.duration = durationSecs
              ff.statusCode = error.response?.status
            }
          return prev
        });
      }

      return Promise.reject(error);
    }
  );

  return instance
}

export const setClients = (accessToken: string, showToast?: any) => {
  setClientsBackend(accessToken, showToast)
  setClientsChatbot(accessToken, showToast)
}

export const setClientsBackend = (accessToken: string, showToast?: any) => {
  const instance = getInstance(accessToken, showToast, urlAPI)
  
  bidingZoneBordersClient = new BidingZoneBordersApi(undefined, urlAPI, instance);
  bidingZoneMappingClient = new BidingZoneMappingApi(undefined, urlAPI, instance);

  dayAheadNetPositionClient = new DayAheadNetPositionApi(undefined, urlAPI, instance);
  intraday1NetPositionClient =  new Intraday1NetPositionApi(undefined, urlAPI, instance);
  intraday2NetPositionClient =  new Intraday2NetPositionApi(undefined, urlAPI, instance);
  intraday3NetPositionClient =  new Intraday3NetPositionApi(undefined, urlAPI, instance);

  dayAheadPriceClient = new DayAheadPriceApi(undefined, urlAPI, instance);
  intraday1PricesClient =  new Intraday1PricesApi(undefined, urlAPI, instance);
  intraday2PricesClient =  new Intraday2PricesApi(undefined, urlAPI, instance);
  intraday3PricesClient =  new Intraday3PricesApi(undefined, urlAPI, instance);

  dayAheadPriceSpreadClient = new DayAheadPriceSpreadApi(undefined, urlAPI, instance);
  intraday1PriceSpreadClient = new Intraday1PriceSpreadApi(undefined, urlAPI, instance);
  intraday2PriceSpreadClient = new Intraday2PriceSpreadApi(undefined, urlAPI, instance);
  intraday3PriceSpreadClient = new Intraday3PriceSpreadApi(undefined, urlAPI, instance);

  bidingZoneVariablesClient =  new BidingZoneVariablesApi(undefined, urlAPI, instance);

  dayAheadScheduledCommercialExchangesClient = new DayAheadScheduledCommercialExchangesApi(undefined, urlAPI, instance);
  intraday1ScheduledCommercialExchangesClient =  new Intraday1ScheduledCommercialExchangesApi(undefined, urlAPI, instance);
  intraday2ScheduledCommercialExchangesClient =  new Intraday2ScheduledCommercialExchangesApi(undefined, urlAPI, instance);
  intraday3ScheduledCommercialExchangesClient =  new Intraday3ScheduledCommercialExchangesApi(undefined, urlAPI, instance);

  bidingZoneBorderVariablesClient = new BidingZoneBorderVariablesApi(undefined, urlAPI, instance)

  urgentMarketMessagesClient = new UrgentMarketMessagesApi(undefined, urlAPI, instance)
};

export const setClientsChatbot = (accessToken: string, showToast?: any) => {
  const instance = getInstance(accessToken, showToast, urlChatbot)

  chatBotQuestionsClient = new ChatBotQuestionsApi(undefined, urlChatbot, instance)
}
